import React from "react"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"
import { navigate } from "gatsby-link"

class RSVP extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      guests: [
        {
          "_1-name": "",
          "_1-vegetarian": "No",
          "_1-vegan": "No",
          "_1-nuts": "No",
          "_1-coeliac": "No",
          "_1-drink": "",
        },
      ],
      email: "",
      isAttending: null,
      guestCount: 1,
      message: "",
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  addGuest(e) {
    e.preventDefault()

    const newGuestCount = this.state.guestCount + 1
    // console.log(this.state.guestCount);
    this.setState({
      guests: [
        ...this.state.guests,
        {
          [`_${newGuestCount}-name`]: "",
          [`_${newGuestCount}-vegetarian`]: null,
          [`_${newGuestCount}-vegan`]: null,
          [`_${newGuestCount}-nuts`]: null,
          [`_${newGuestCount}-coeliac`]: null,
          [`_${newGuestCount}-drink`]: null,
        },
      ],
      guestCount: newGuestCount,
    })
  }
  removeGuest(e) {
    e.preventDefault()
    const guests = this.state.guests
    const newGuestCount = this.state.guestCount - 1

    // console.log(this.state.guestCount);
    guests.splice(newGuestCount, 1)

    // console.log(newGuestCount);
    // console.log(guests.length);
    this.setState({
      guests,
      guestCount: newGuestCount,
    })
    // this.setState({ guests, guestCount: this.state.guestCount -1, })
    // console.log(this.state.guestCount);
    // this.setState({ guestCount: this.state.guestCount -1, })
  }
  handleRadioChange = e => {
    const target = e.target
    const value = target.type === "radio" ? target.checked : target.value
    const isAttending = target.value === "Attending" ? true : false

    // console.log(target.checked);
    // console.log(target.value);

    const name = target.name
    this.setState({
      [name]: value,
      isAttending,
    })
  }
  handleInputChange = (index, e) => {
    if (index !== null) {
      // let guests = this.state.guests;
      // guests[index][e.target.name] = e.target.value;
      // this.setState({ guests });
    } else {
      const target = e.target
      const value = target.type === "checkbox" ? target.checked : target.value
      // isAttending
      const name = target.name
      this.setState({
        [name]: value,
      })
    }
  }
  handleServerResponse = (ok, msg, form) => {
    this.setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
      navigate("/thanks")
    } else {
      console.log("nah")
    }
  }
  setServerState = (submitting, status) => {
    this.setState({
      submitting,
      status,
    })
  }
  encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  handleSubmit = event => {
    event.preventDefault()
    const form = event.target
    this.setServerState({ submitting: true })
    const formInputs = Array.from(event.target.getElementsByTagName("input"))
    let guestData = []
    formInputs.map(input => {
      if (
        (input.name.startsWith("vegetarian-") ||
          input.name.startsWith("vegan-") ||
          input.name.startsWith("nuts-") ||
          input.name.startsWith("coeliac-")) &&
        input.type === "checkbox"
      ) {
        return (guestData[input.name] = input.checked ? "Yes" : "No")
      } else if (input.name.startsWith("attending") && input.type === "radio") {
        return (guestData[input.name] = this.state.isAttending
          ? "Attending"
          : "Not Attending")
      } else if (
        input.name.startsWith("name-") ||
        input.name.startsWith("vegetarian-") ||
        input.name.startsWith("vegan-") ||
        input.name.startsWith("nuts-") ||
        input.name.startsWith("coeliac-") ||
        input.name.startsWith("drink-")
      ) {
        return (guestData[input.name] = input.value)
      } else {
        return false
      }
    })

    const formData = []
    formData["form-name"] = event.target.getAttribute("name")
    formData["guest-count"] = this.state.guestCount
    formData["email"] = this.state.email
    formData["attending"] = this.state.isAttending
      ? "Attending"
      : "Not Attending"
    formData["message"] = this.state.message
    // fetch("/", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //   body: this.encode({
    //     ...guestData,
    //     ...formData
    //   })
    // }).then(() => navigate("/thank-you")).catch(error => alert(error))
    axios({
      method: "post",
      url: "https://formspree.io/meqbnapr",
      data: new FormData(form),
    })
      .then(r => {
        this.handleServerResponse(true, "Thanks!", form)
      })
      .catch(r => {
        this.handleServerResponse(false, r.response.data.error, form)
      })
  }
  render() {
    return (
      <div className="rsvp bg-primary text-secondary text-xl pt-8 pb-4 lg:py-20">
        <div className="container-fluid flex flex-wrap ">
          <div className="w-full md:w-6/12 md:pr-5 md:pl-3 order-12 md:order-1">
            {/* <form method="post" name="RSVP" action="https://formspree.io/f/mdoyrrda" className=""> */}
            <form name="RSVP" onSubmit={e => this.handleSubmit(e)}>
              <input
                type="hidden"
                name="guest-count"
                value={this.state.guestCount}
              />
              <div className="flex flex-wrap mb-2">
                <label
                  htmlFor="attending"
                  className="label flex w-full text-2xl font-normal mb-1"
                >
                  Attending
                </label>
                <div className="w-full flex flex-wrap justify-start items-center mb-2">
                  <input
                    type="radio"
                    name="attending"
                    value="Attending"
                    checked={this.state.isAttending === true}
                    onChange={e => this.handleRadioChange(e)}
                    className="radio mr-2 mb-2"
                    id="yes"
                    required
                  />
                  <label htmlFor="yes" className="mr-8">
                    Yes
                  </label>
                  <input
                    type="radio"
                    name="attending"
                    value="Not Attending"
                    checked={this.state.isAttending === false}
                    onChange={e => this.handleRadioChange(e)}
                    className="radio mr-2 mb-2"
                    id="no"
                    required
                  />
                  <label htmlFor="no" className="mr-8">
                    No
                  </label>
                </div>
              </div>

              {this.state.guests.map((guest, index) => (
                <div key={++index}>
                  <p className="mb-0">
                    <label
                      htmlFor={`_${index}-name`}
                      className="flex flex-wrap"
                    >
                      <span className="label flex w-full text-2xl font-normal mb-1">
                        {index > 1 ? `Guest ${index}` : `Name`}
                      </span>

                      <input
                        type="text"
                        name={`_${index}-name`}
                        placeholder="Guest Name"
                        required
                        // value={guest[`name-${index}`] || ''}
                        onChange={e => this.handleInputChange(index, e)}
                        // className="input flex w-full mb-4 p-2 text-black "
                        className="input flex w-full mb-4 p-2 text-secondary bg-transparent border-secondary border border-solid placeholder-secondary focus:outline-none focus:border-tertiary focus:text-tertiary focus:placeholder-tertiary"
                        id={`_${index}-name`}
                      />
                    </label>
                  </p>
                  {this.state.isAttending === true && (
                    <div className="flex flex-wrap">
                      <label
                        htmlFor="dietary"
                        className="label flex w-full text-2xl font-normal mb-1"
                      >
                        Dietary
                      </label>
                      <div className="w-full flex flex-wrap justify-start items-center mb-3 md:mb-2">
                        <input
                          type="checkbox"
                          name={`_${index}-vegetarian`}
                          value="Yes"
                          // checked={guest.vegetarian}
                          // onChange={e => this.handleInputChange(index, e)}
                          className="checkbox mr-2 mb-2"
                          id={`_${index}-vegetarian`}
                        />
                        <label
                          htmlFor={`_${index}-vegetarian`}
                          className="mr-8 mb-2"
                        >
                          Vegetarian
                        </label>
                        <input
                          type="checkbox"
                          name={`_${index}-vegan`}
                          value="Yes"
                          // checked={guest.vegan}
                          // onChange={e => this.handleInputChange(index, e)}
                          className="checkbox mr-2 mb-2"
                          id={`_${index}-vegan`}
                        />
                        <label
                          htmlFor={`_${index}-vegan`}
                          className="mr-8 mb-2"
                        >
                          Vegan
                        </label>
                        <input
                          type="checkbox"
                          name={`_${index}-nuts`}
                          value="Yes"
                          // checked={guest.nuts}
                          // onChange={e => this.handleInputChange(index, e)}
                          className="checkbox mr-2 mb-2"
                          id={`_${index}-nuts`}
                        />
                        <label htmlFor={`_${index}-nuts`} className="mr-8 mb-2">
                          Nut allergy
                        </label>
                        <input
                          type="checkbox"
                          name={`_${index}-coeliac`}
                          value="Yes"
                          // checked={guest.coeliac}
                          // onChange={e => this.handleInputChange(index, e)}
                          className="checkbox mr-2 mb-2"
                          id={`_${index}-coeliac`}
                        />
                        <label
                          htmlFor={`_${index}-coeliac`}
                          className="mr-8 mb-2"
                        >
                          Gluten free
                        </label>
                      </div>
                      <p className="w-full flex flex-wrap justify-start items-center mb-3 md:mb-2">
                        <label
                          htmlFor="drink"
                          className="w-full flex flex-wrap"
                        >
                          <span className="label flex w-full text-2xl font-normal mb-1">
                            What is your drink of choice?
                          </span>
                          <input
                            type="text"
                            name={`_${index}-drink`}
                            id={`_${index}-drink`}
                            placeholder="Drink name"
                            onChange={e => this.handleInputChange(index, e)}
                            className="input flex w-full mb-4 p-2 text-secondary bg-transparent border-secondary border border-solid placeholder-secondary focus:outline-none focus:border-tertiary focus:text-tertiary focus:placeholder-tertiary"
                          />
                        </label>
                      </p>
                    </div>
                  )}
                </div>
              ))}

              {this.state.isAttending === true && (
                <div className="border-b border-solid border-secondary mb-4">
                  <button
                    onClick={e => this.addGuest(e)}
                    className="rsvp-submit w-full md:w-auto text-left mb-2 md:mb-4"
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add guest
                  </button>
                  {this.state.guestCount > 1 ? (
                    <button
                      onClick={e => this.removeGuest(e)}
                      className="rsvp-submit w-full md:w-auto text-left mb-2 md:mb-4 md:ml-4"
                    >
                      <FontAwesomeIcon icon={faMinus} className="mr-1" />
                      Remove guest
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <p>
                <label htmlFor="email">
                  <span className="label flex w-full text-2xl font-normal mb-1">
                    Email*
                  </span>
                  <input
                    type="email"
                    name="email"
                    placeholder="your@email.com"
                    value={this.state.email || ""}
                    onChange={e => this.handleInputChange(null, e)}
                    className="input flex w-full mb-4 p-2 text-secondary bg-transparent border-secondary border border-solid placeholder-secondary focus:outline-none focus:border-tertiary focus:text-tertiary focus:placeholder-tertiary"
                    required
                  />
                </label>
              </p>
              <p>
                <label htmlFor="message">
                  <span className="label flex w-full text-2xl font-normal mb-1">
                    Message
                  </span>
                  <textarea
                    name="message"
                    placeholder="Is there anything you'd like us to know?"
                    value={this.state.message}
                    onChange={e => this.handleInputChange(null, e)}
                    className="input flex w-full mb-4 p-2 text-secondary bg-transparent border-secondary border border-solid placeholder-secondary focus:outline-none focus:border-tertiary focus:text-tertiary focus:placeholder-tertiary"
                  />
                </label>
              </p>
              <p>
                <input type="text" name="_gotcha" style={{ display: "none" }} />
                <button
                  type="submit"
                  className="rsvp-submit text-2xl font-normal -ml-4 p-4"
                >
                  {this.state.isAttending === false
                    ? `Sorry you can't make it`
                    : `Let's celebrate!`}
                </button>
                {this.state.status && (
                  <p className={!this.state.status.ok ? "errorMsg" : ""}>
                    {this.state.status.msg}
                  </p>
                )}
              </p>
            </form>
          </div>
          <div className="w-full md:w-6/12 md:pr-5 md:pl-3 order-1 md:order-12">
            <h1 className="text-4xl xs:text-5xl leading-tight md:text-6xl lg:text-8xl font-display mb-2 sm:mb-0">
              RSVP
            </h1>
            <p className="text-xl">
              Please fill out the form and let us know if you're attending
              before <strong>30th April 2024</strong>.
            </p>
            <p className="text-xl">
              RSVP for every guest on your invite and provide any dietary
              requirements.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default RSVP
