import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import RSVP from "../components/RSVP"

class RSVPPage extends React.Component {
  render () {
    return(
      <Layout>
        <SEO title="RSVP" />

        <RSVP />

      </Layout>
    )
  }
}
export default RSVPPage
